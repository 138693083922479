import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { Layout, MePlusYou } from '../components'
import { Link } from 'gatsby'

const Container = styled.div`
	margin-top: 3rem;
	h2 {
		margin-top: 1rem;
	}
`

const ProjectGrid = styled.div`
	display: grid;
  grid-template-rows: auto;
	grid-template-columns: 1fr;
  grid-template-areas: ".";
	grid-gap: 3rem;
	margin: 3rem 0rem;
  @media (min-width: ${props => props.theme.breakpoints.m}) {
		grid-template-columns: 1fr 1fr;
  	grid-template-areas: ". .";
	}
  @media (min-width: ${props => props.theme.breakpoints.l}) {
		grid-gap: 6rem;
	}
`

const ProjectContainer = styled.div`
	display: flex;
	flex-direction: column;

	p {
		margin-top: 1rem;
	}
`

const ProjectHero = styled.img`
	width: auto;
	height: auto;
`

const ProjectItem = ({ project, key }) => {
	const { uid, name, hero } = project
	return (
		<Link to={`/verkefni/${uid}`} key={key}>
			<ProjectContainer>
				<ProjectHero src={hero.url}/>
				<p>{name}</p>
			</ProjectContainer>
		</Link>
	)
}

class Index extends Component {
  render() {
    const {
      data
		} = this.props
		const projects = data.projects.nodes.map(node => {
			return {
				name: node.data.project_name.text,
				uid: node.uid,
				hero: node.data.project_hero
			}
		})
    return (
      <Layout>
				<Container>
					<MePlusYou/>
					<h2>Verkefni</h2>
					<ProjectGrid>
						{projects.map((project, index) => {
							return (
								<ProjectItem key={index} project={project} />
							)
						})}
					</ProjectGrid>
				</Container>
      </Layout>
    )
  }
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
		projects: PropTypes.shape({
			nodes: PropTypes.arrayOf(
				PropTypes.shape({
					project_name: PropTypes.shape({
						text: PropTypes.string.isRequired,
					}),
					project_hero: PropTypes.shape({
						url: PropTypes.string.isRequired,
					}),
					uid: PropTypes.string.isRequired
				})
			)
		})
  }).isRequired,
}

export const pageQuery = graphql`
	query ProjectsQuery {
		projects: allPrismicProject {
			nodes {
				data {
					project_name {
						text
					}
					project_hero {
						url
					}
				}
				uid
			}
		}
	}
`
